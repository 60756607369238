import { IOnboardingFlowDetails, IOnboardingFlowPrescription } from 'interfaces/onboarding-flow'

export let generatedPrescriptionIds = [] // keep track of generated prescription ids
const generatedPharmacyIds = [] // keep track of generated pharmacy ids

export const generatePrescriptionId = (): string => {
    const lastPrescriptionId = generatedPrescriptionIds[generatedPrescriptionIds.length - 1]?.split?.('-')?.[1]
    let newPrescriptionId = 'prescription-1'

    if (lastPrescriptionId) {
        newPrescriptionId = 'prescription-' + (Number(lastPrescriptionId) + 1)
    }

    generatedPrescriptionIds.push(newPrescriptionId)

    return newPrescriptionId
}

export const clearPrescriptionIds = (): void => {
    generatedPrescriptionIds = []
}

const generatePharmacyId = (): string => {
    const lastPharmacyId = generatedPharmacyIds[generatedPharmacyIds.length - 1]?.split?.('#')?.[1]
    let newPharmacyId = 'pharmacy#1'

    if (lastPharmacyId) {
        newPharmacyId = 'pharmacy#' + (Number(lastPharmacyId) + 1)
    }

    generatedPharmacyIds.push(newPharmacyId)

    return newPharmacyId
}

export const onboardingFlowInitialState: IOnboardingFlowDetails = {
    first_name: '',
    last_name: '',
    address_state: '',
    email: '',
    phone_number: '',
    phone_number_transactional_messaging_consent_given: true,
    date_of_birth: '',
    prescriptions: [],
    allergies: [],
    authorization_for_use_and_disclosure_agreement: false,
    acknowledge_not_crc_agreement: false,
    contact_prescriber_consent_given: false,
    acknowledge_allow_stack_fills: false,
    sex_assigned_at_birth: '',
}

export const ONBOARDING_FLOW_REDUCER_ACTION_TYPES = {
    SET_STATE: 'set_state',
    SET_FIRST_NAME: 'set_first_name',
    SET_LAST_NAME: 'set_last_name',
    SET_ADDRESS_STATE: 'set_address_state',
    SET_EMAIL: 'set_email',
    SET_DOB: 'set_dob',
    ADD_PRESCRIPTION: 'add_prescription',
    UPDATE_PRESCRIPTION: 'update_prescription',
    REMOVE_PRESCRIPTION: 'remove_prescription',
    SET_DRUG: 'set_drug',
    SET_DAY_SUPPLY: 'set_day_supply',
    SET_DRUG_FORM_ID: 'set_drug_form_id',
    SET_PILLS_PER_DAY: 'set_pills_per_day',
    SET_TOTAL_INPUTTED_PILLS: 'set_total_inputted_pills',
    SET_BULK_SUPPLY_CHECKED: 'set_bulk_supply_checked',
    ADD_PHARMACY: 'add_pharmacy',
    SET_PHARMACY: 'set_pharmacy',
    SET_PHARMACY_PHONE_NUMBER: 'set_pharmacy_phone_number',
    SET_PHARMACY_ADDRESS: 'set_pharmacy_address',
    SET_ALLERGIES: 'set_allergies',
    SET_PHONE_NUMBER: 'set_phone_number',
    SET_PHONE_NUMBER_CONSENT_GIVEN: 'set_phone_number_consent_given',
    SET_PERSONAL_HEALTH_INFORMATION_DISCLOUSER_CONSENT_GIVEN: 'set_personal_health_information_disclosure_given',
    SET_NOT_CHILD_SAFE_CONSENT_GIVEN: 'set_not_child_safe_consent_given',
    SET_PILL_QUANTITY_INPUT_METHOD: 'set_pill_quantity_input_method',
    SET_CONTACT_PRESCRIBER_CONSENT_GIVEN: 'set_prescriber_consent_given',
    SET_ACKNOWLEDGE_ALLOW_STACK_FILLS: 'set_acknowledge_allow_stack_fills_consent',
    CLEAN_PRESCRIPTIONS: 'clean_prescriptions', // removes incomplete prescriptions
    SET_SEX_ASSIGNED_AT_BIRTH: 'set_sex_assigned_at_birth',
} as const

export const onboardingFlowReducer = (
    state: IOnboardingFlowDetails,
    action: { type: string; payload: any },
): IOnboardingFlowDetails => {
    switch (action.type) {
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_STATE: {
            return action.payload
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_FIRST_NAME: {
            return {
                ...state,
                first_name: action.payload.first_name,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_LAST_NAME: {
            return {
                ...state,
                last_name: action.payload.last_name,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_ADDRESS_STATE: {
            return {
                ...state,
                address_state: action.payload.address_state,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_EMAIL: {
            return {
                ...state,
                email: action.payload.email,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_DOB: {
            return {
                ...state,
                date_of_birth: action.payload.date_of_birth,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.ADD_PRESCRIPTION: {
            // create new prescription with defaults
            const newPrescription: IOnboardingFlowPrescription = {
                pills_per_day: 1,
                pill_quantity_input_method: 'composite',
                bulk_supply_checked: true,
                day_supply: '90 Days',
                total_pills_direct_input: 1,
                ...action.payload,
            }

            return {
                ...state,
                prescriptions: [...state.prescriptions, newPrescription],
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.UPDATE_PRESCRIPTION: {
            const prescriptionId = action.payload.id

            const updatedPrescriptions: IOnboardingFlowPrescription[] = state.prescriptions.map((prescription) => {
                if (prescription.id !== prescriptionId) {
                    return prescription
                }

                return {
                    ...prescription,
                    ...action.payload,
                }
            })

            return {
                ...state,
                prescriptions: updatedPrescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.REMOVE_PRESCRIPTION: {
            const prescriptionId = action.payload.id

            const updatedPrescriptions = state.prescriptions.map((prescription) => {
                if (prescription.id !== prescriptionId) {
                    return prescription
                }

                return {
                    ...prescription,
                    deleted: true, // soft delete
                }
            })

            return {
                ...state,
                prescriptions: updatedPrescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_DRUG: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].drug = action.payload.drug

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_DRUG_FORM_ID: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].drug_form = action.payload.drug_form

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_DAY_SUPPLY: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].day_supply = action.payload.day_supply

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PILLS_PER_DAY: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].pills_per_day = action.payload.pills_per_day

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_TOTAL_INPUTTED_PILLS: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].total_pills_direct_input = action.payload.total_pills_direct_input

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_BULK_SUPPLY_CHECKED: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]
            prescriptions[prescriptionIndex].bulk_supply_checked = action.payload.bulk_supply_checked

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.ADD_PHARMACY: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]

            prescriptions[prescriptionIndex].pharmacy = {
                ...action.payload.pharmacy,
                id: action.payload.pharmacy.id ?? generatePharmacyId(),
            }

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PHARMACY: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]

            prescriptions[prescriptionIndex].pharmacy = action.payload.pharmacy

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PHARMACY_PHONE_NUMBER: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]

            prescriptions[prescriptionIndex].pharmacy = {
                ...prescriptions[prescriptionIndex]?.pharmacy,
                phone_number: action.payload.phone_number,
            }

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PHARMACY_ADDRESS: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]

            prescriptions[prescriptionIndex].pharmacy = {
                ...prescriptions[prescriptionIndex]?.pharmacy,
                // undefined removes field from form payload
                address: action.payload.address || undefined,
                google_maps_place_id: action.payload.google_maps_place_id || undefined,
            }

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_ALLERGIES: {
            return {
                ...state,
                allergies: action.payload.allergies,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PHONE_NUMBER: {
            return {
                ...state,
                phone_number: action.payload.phone_number,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PHONE_NUMBER_CONSENT_GIVEN: {
            return {
                ...state,
                phone_number_transactional_messaging_consent_given:
                    action.payload.phone_number_transactional_messaging_consent_given,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PERSONAL_HEALTH_INFORMATION_DISCLOUSER_CONSENT_GIVEN: {
            return {
                ...state,
                authorization_for_use_and_disclosure_agreement:
                    action.payload.authorization_for_use_and_disclosure_agreement,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_NOT_CHILD_SAFE_CONSENT_GIVEN: {
            return {
                ...state,
                acknowledge_not_crc_agreement: action.payload.acknowledge_not_crc_agreement,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_ACKNOWLEDGE_ALLOW_STACK_FILLS: {
            return {
                ...state,
                acknowledge_allow_stack_fills: action.payload.acknowledge_allow_stack_fills,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_PILL_QUANTITY_INPUT_METHOD: {
            const prescriptionId = action.payload.prescriptionId
            const prescriptionIndex = state.prescriptions.findIndex((p) => p.id === prescriptionId)

            const prescriptions = [...state.prescriptions]

            prescriptions[prescriptionIndex].pill_quantity_input_method = action.payload.pill_quantity_input_method

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_CONTACT_PRESCRIBER_CONSENT_GIVEN: {
            return {
                ...state,
                contact_prescriber_consent_given: action.payload.contact_prescriber_consent_given,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.CLEAN_PRESCRIPTIONS: {
            const prescriptions = state.prescriptions.filter((prescription) => {
                const isIncomplete =
                    !prescription.drug_form ||
                    !prescription.drug ||
                    !prescription.pharmacy ||
                    !prescription.pharmacy?.phone_number

                return !isIncomplete
            })

            return {
                ...state,
                prescriptions,
            }
        }
        case ONBOARDING_FLOW_REDUCER_ACTION_TYPES.SET_SEX_ASSIGNED_AT_BIRTH: {
            return {
                ...state,
                sex_assigned_at_birth: action.payload.sex_assigned_at_birth,
            }
        }
        default:
            throw Error(`Unknown action '${action.type}' dispatched.`)
    }
}
